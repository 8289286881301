(function ($) {
    // Handler for .ready() called.
    var processing;
    /** Custom Validator**/
    $.validator.addMethod("alphaonly", function (value, element) {
        var regex = new RegExp("^[a-zA-Z ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphabetic characters or space.");

    //Validation for ABS form
    $.validator.addMethod("alphanum", function (value, element) {
        var regex = new RegExp("^[-a-zA-Z0-9_\\-!&#*'\" ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphanumeric characters.");

    $.validator.addMethod("validPhone", function (value, element) {
        value = value.replace(/[^0-9\-]/g, '');
        this.optional(element) || !value.match(/\d{3}-?\d{3}-?\d{4}$/); ///1-?\d{3}-?\d{3}-?\d{4}$
    }, "Please specify a valid phone number.");

    $.validator.addMethod("simplePhone", function(value, element) {
        return this.optional(element) || /^[0-9\(\)\+\-]+$/.test(value);
    }, "Please enter a valid phone number with numbers, parentheses, dashes and + only.");
      

    $.validator.addMethod("notempty", function (value, element) {
        var key = value,
            tempValue = $.trim(value);

        if (tempValue == '') {
            return false;
        }
        return true;
    }, "Please enter a value.");

    $.validator.addMethod("nophp", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\?[=|php]?[\s\S]*?\?>/i.test(value)) {
            return false;
        }
        return true;
    }, "Only plain text allowed.");

    $.validator.addMethod("nohtml", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\/?[a-z][\s\S]*>/i.test(value)) {
            return false;
        }
        return true;
    }, "No html allowed.");

    function _convertSpecialChars(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }


    $(window).on('load', function () {
        if ($('.doors-wrapper .door').length > 0) {
            $('.doors-wrapper').slick({
                infinite: true,
                slidesToShow: 1,
                adaptiveHeight: true,
                accessibility: true,
                prevArrow: '<button class="prev"><i class="chevron-icon left"></i></button>',
                nextArrow: '<button class="next"><i class="chevron-icon right"></i></button>',
                slidesToScroll: 1,
                dots: false,
                mobileFirst: true,
                autoplay: false,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 1020,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                ]
            });
        }
        if ($(".animatecss").length > 0) {
            var $collection = $('.animatecss');
            $collection.each(function () {
                new Waypoint.Inview({
                    element: this,
                    enter: function (direction) {
                        if (!isMobile()) {
                            $(this.element).removeClass($(this.element).data("animation-out") + " xhidden").addClass($(this.element).data('animation'));
                        }
                    },
                    exited: function (direction) { //changed from exited to exit
                        if (!isMobile()) {
                            if ($(this.element).hasClass('anim2')) {
                                $(this.element).removeClass($(this.element).data("anim2"));
                            }
                            $(this.element).removeClass($(this.element).data("animation")).addClass($(this.element).data("animation-out"));
                        }
                    },
                })
            });
        }
    });

    $('select').on('change', function () {
        if(!isEmpty($(this).val())){
            $(this).addClass('dirty');
        } else {
            $(this).removeClass('dirty');
        }
    });

    $('.attendance-resp input[type=radio]').on('change', function() {
        if($(this).val() == 1){
            $('#timeslot-selection').addClass('attending').removeClass('not-attending maybe');
            $('#slot-inputs').removeAttr('disabled');
        } else if($(this).val() == 0){
            $('#timeslot-selection').addClass('not-attending').removeClass('attending maybe');
            $('#slot-inputs').attr('disabled', 'disabled');
        } else {
            $('#slot-inputs').attr('disabled', 'disabled');
            $('#timeslot-selection').addClass('maybe').removeClass('attending not-attending');
        }
    });


    // if ($("#fldphone").length > 0) {
    //     $("#fldphone").mask("999-999-9999");
    // }

    $('#friend-frm').validate({
        ignore: ".ignore",
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.field-input'));
        },
        highlight: function (element) {
            $(element).parents('.field-input').addClass('has-error');
        },
        unhighlight: function (element) {
            $(element).parents('.field-input').removeClass('has-error');
        },
        rules: {
            'entry[friend_name]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[friend_email]': { required: true, email: true, notempty: true, nohtml: true, nophp: true },
        },
        messages: {
            'entry[friend_name]': { required: 'Please enter your name.' },
            'entry[friend_email]': { required: 'Please enter your email address.', email: 'Please enter a valid email address.' },
        },
        invalidHandler: function (event, validator) {
            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).parents('.field-group').offset().top
            }, 800);
        },
        submitHandler: function (form) {
            //new FormData(this),
            processForm(new FormData(form));
            //processEntry('form', $(form).serialize());
            return false;
        }
    });

    $("#reg-frm").validate({
        ignore: ".ignore",
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.field-input'));
        },
        highlight: function (element) {
            $(element).parents('.field-input').addClass('has-error');
        },
        unhighlight: function (element) {
            $(element).parents('.field-input').removeClass('has-error');
        },
        rules: {
            'entry[fullname]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[publication]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[title]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[email]': { required: true, email: true, notempty: true, nohtml: true, nophp: true },
            'entry[handles]': { nohtml: true, nophp: true },
            'entry[address]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[city]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[country]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[phone]': { required: true, simplePhone: true},
            'entry[timeslot]': { required: true},
            'entry[attending]': {required: true },
            'hiddenRecaptcha': {
                required: function () {
                    if (grecaptcha.getResponse() == '') {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        messages: {
            'entry[fullname]': { required: 'Please enter your full name.' },
            'entry[publication]': { required: 'Please enter your publication.' },
            'entry[title]': { required: 'Please enter your title.' },
            'entry[address]': { required: 'Please enter your address.' },
            'entry[city]': { required: 'Please enter your address.' },
            'entry[country]': { required: 'Please enter your country.' },
            'entry[phone]': { required: 'Please enter a phone number.' },
            'entry[attending]': { required: 'Are you attending?'  },
            'entry[timeslot]': { required: 'Please select a timeslot.' },
            'entry[email]': { required: 'Please enter your email address.', email: 'Please enter a valid email address.' },
        },
        invalidHandler: function (event, validator) {
            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).parents('.field-group').offset().top
            }, 800);
        },
        submitHandler: function (form) {
            //new FormData(this),
            processForm(new FormData(form));
            //processEntry('form', $(form).serialize());
            return false;
        }
    });

    
    $('.goto').on('click', function(e){
        e.preventDefault();
        var _target = null;
        if($('#' + $(this).data('section')).length > 0){
          _target = $('#' + $(this).data('section'));
        } else if($('.' + $(this).data('section')).length > 0){
          _target = $('.' + $(this).data('section'));
          $('button.video-section__poster').trigger('click');
        }
        if(_target !== null){
          $('html, body').animate({
              scrollTop: _target.offset().top - 20,
          }, 1200);
        }
    });
    
    //Create expression and trap all external links
    $.expr[':'].external = function (obj) {
        return !obj.href.match(/^mailto\:/)
            && (obj.hostname != location.hostname);
    };

    $('a[href$=".pdf"]').prop('target', '_blank').addClass('pdf');
    $('a:external:not(.sub)').addClass('external-link');
    $('.external-link').attr('aria-describedby', 'off-site');
    $('a[href="#"]').attr('role', 'button');
    var externalLink;
    checkingProcessing = false;
    $('.btn.share').on('click', function(){
        _addShareEntry();
    });

    function checkinGuest(obj){
        checkingProcessing = true;
        obj.addClass('loading');
        formData = new FormData();
        formData.append("token", obj.data('token'));
        formData.append("op", 'checkin');
        $('#response-cont .resp-box').fadeOut();
        $.ajax({
            type: "POST",
            dataType: 'json',
            url: '../misc/ajax.php',
            data: formData,
            encode: true,
            contentType: false,
            cache: false,
            processData:false,
            beforeSend: function (xhr) {
            }
        })
        .done(function (resp) {
            if (!isEmpty(resp.success)) {
                $('.checkin-guest').fadeOut(200).remove();
                $('#response-cont .resp-box.success').fadeIn();
            } else if (!isEmpty(resp.error)) {
                $('#response-cont .resp-box.error').fadeIn();
            }
        })
        .fail(function () {
            // Some Error Occured
        })
        .always(function (data) {
            checkingProcessing = false;
            obj.removeClass('loading');
        });
    }


    function _addShareEntry(){
        $('#error-bucket, #success-bucket').fadeOut().remove();
        if (processing) {
            return;
        }

        processing = true;
        formData = new FormData();
        formData.append('op', 'share-entry');
        $.ajax({
            type: "POST",
            // dataType: 'json',
            url: 'misc/ajax.php',
            data: formData,
            contentType: false,
            cache: false,
            processData:false,
        })
            .done(function (resp) {
               //Don't need to do anything
            })
            .fail(function () {
                // Some Error Occured
            })
            .always(function (data) {
                processing = false;
            });
    }

    function processForm(formData) {
        $('#error-bucket, #success-bucket').fadeOut().remove();
        if (processing) {
            alert('Your submission is being processed, please wait.');
            return;
        }

        var button = $('.frm-submit');
        button.addClass('loading');
        processing = true;
        formData.append('op', 'form-submission');

        $.ajax({
            type: "POST",
            // dataType: 'json',
            url: 'misc/ajax.php',
            data: formData,
            contentType: false,
            cache: false,
            processData:false,
            beforeSend: function (xhr) {
                $("#err").fadeOut();
            }
        })
            .done(function (resp) {
                if (!isEmpty(resp.success)) {
                    if(resp.redirect == 'inline'){
                        $('#frd-form-cont').html('<h3 class="blue pt-40">' +  resp.msg + '</h3>');
                    } else{
                        window.location = resp.redirect;
                    }
                } else if (!isEmpty(resp.error)) {
                    if ($('#reg-frm, #friend-frm').length > 0) {
                        $('#reg-frm, #friend-frm').prepend('<div id="error-bucket">' + resp.error + '</div>');
                    }
                }
            })
            .fail(function () {
                // Some Error Occured
            })
            .always(function (data) {
                processing = false;
                if($('.captcha-cont').length > 0){
                    grecaptcha.reset();
                }
                button.removeClass('loading');
                if ($('#error-bucket').length > 0) {
                    $('html, body').animate({
                        scrollTop: $('#error-bucket').offset().top - 10
                    }, 800);
                }
            });
    }


    function _resetform(){
        $("#reg-frm")[0].reset();
        if ($('#success-bucket').length > 0) {
            $('html, body').animate({
                scrollTop: $('#success-bucket').offset().top - 10
            }, 1000);
        }
    }

    function isMobile() {
        return ($(window).width() < 768);
    }

    function isEmpty(mixed_var) {
        mixed_var = jQuery.trim(mixed_var);
        var key;
        if (mixed_var === '' || mixed_var === 0 || mixed_var === '0' || mixed_var === null || mixed_var === false || typeof mixed_var === 'undefined') {
            return true;
        }

        if (typeof mixed_var === 'object') {
            for (key in mixed_var) {
                return false;
            }
            return true;
        }
        return false;
    }

})(jQuery);